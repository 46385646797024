/* eslint-disable max-lines */
import EventIcon from '@mui/icons-material/Event';
import { styled, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
    cancelInterview,
    getInterviewsForUser,
    updateInterviewStatus
} from '../../../services/interviews';
import { addNetworkConnection } from '../../../services/user';
import thumbsUp from '../../../static/icons/thumbs-up.svg';
import { UserContext } from '../../context/UserContext';
import { BaseContainer } from '../../global/Core';

import FollowupCard from './FollowupCard';
import InterviewCard from './InterviewCard';
import ModalSection from './ModalSection';

import type { Interview } from '../../../types/interview';
import type { SimpleJob } from '../../../types/job';
import type { User } from '../../../types/user';

const StyledGrid = styled('div')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    gap: 1rem;
`;

export default function UpcomingInterviews() {
    const [interviews, setInterviews] = useState<{
        soon: Interview[];
        upcoming: Interview[];
        completed: Interview[];
    }>({
        soon: [],
        upcoming: [],
        completed: []
    });
    const [followup, setFollowup] = useState<
        { job: SimpleJob; participant: User | null; interviews: Interview[] }[]
    >([]);
    const [modal, setModal] = useState<{
        type:
            | 'addContact'
            | 'removeContact'
            | 'cancelInterview'
            | 'viewProfile'
            | '';
        interview: Interview;
    }>({
        type: '',
        interview: {} as Interview
    });
    const [tab, setTab] = useState<'upcoming' | 'completed' | 'followup'>(
        'upcoming'
    );
    const { user } = useContext(UserContext);

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: 'upcoming' | 'completed' | 'followup'
    ) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (!user.id) return;
        getInterviewsForUser(user.id).then((res: Interview[]) => {
            const completed: Interview[] = [];
            const soon: Interview[] = [];
            const upcoming: Interview[] = [];
            res.forEach((interview: Interview) => {
                const minutesLeft = interview.startTime
                    .diff(DateTime.now())
                    .as('minutes');
                if (interview.status === 'completed') {
                    completed.push(interview);
                } else if (interview.status === 'follow_up_requested') {
                    setFollowup((prev) => {
                        const jobIndex = prev.findIndex(
                            (job) => job.job.id === interview.job.id
                        );
                        if (jobIndex === -1) {
                            return [
                                ...prev,
                                {
                                    job: interview.job,
                                    participant: interview.participant,
                                    interviews: [interview]
                                }
                            ];
                        }
                        const newJob = {
                            ...prev[jobIndex],
                            interviews: [
                                ...prev[jobIndex].interviews,
                                interview
                            ]
                        };
                        return [
                            ...prev.slice(0, jobIndex),
                            newJob,
                            ...prev.slice(jobIndex + 1)
                        ];
                    });
                } else if (interview.status === 'scheduled') {
                    if (
                        minutesLeft >= 0 - interview.duration + 3 &&
                        minutesLeft < 60
                    ) {
                        soon.push(interview);
                    } else if (minutesLeft >= 60) {
                        upcoming.push(interview);
                    }
                }
            });
            setInterviews({
                soon,
                upcoming,
                completed
            });
        });
    }, [user.id]);

    const removeInterviewByID = (
        interviewID: number,
        section: 'soon' | 'upcoming' | 'completed'
    ) => {
        setInterviews((prev) => {
            const newInterviews = prev[section].filter(
                (interview) => interview.id !== interviewID
            );
            return {
                ...prev,
                [section]: newInterviews
            };
        });
    };

    const addContactOnClick = (interview: Interview) => {
        let addNetworkPromise = addNetworkConnection(
            interview.participant?.id || 0,
            user.id
        );
        if (user.levylRole === 'candidate') {
            addNetworkPromise = addNetworkConnection(
                user.id,
                interview.participant?.id || 0
            );
        }
        return updateInterviewStatus(
            interview.id,
            'completed_network_declined'
        ).then(() =>
            addNetworkPromise.then(() => {
                removeInterviewByID(interview.id, 'completed');
                setModal({ type: '', interview: {} as Interview });
            })
        );
    };

    const removeContactOnClick = (interview: Interview) =>
        updateInterviewStatus(interview.id, 'completed_network_declined').then(
            () => {
                removeInterviewByID(interview.id, 'completed');
                return setModal({ type: '', interview: {} as Interview });
            }
        );

    const cancelInterviewOnClick = (interview: Interview) =>
        cancelInterview(interview.id, user.levylRole === 'candidate').then(
            () => {
                removeInterviewByID(interview.id, 'upcoming');
                setModal({ type: '', interview: {} as Interview });
            }
        );

    const upcomingByDate = useMemo(() => {
        const upcomingDates: { [key: string]: Interview[] } = {};
        interviews.upcoming.forEach((interview) => {
            const date = interview.startTime.toFormat('yyyy-MM-dd');
            if (!upcomingDates[date]) {
                upcomingDates[date] = [];
            }
            upcomingDates[date].push(interview);
        });
        return upcomingDates;
    }, [interviews.upcoming]);

    return (
        <>
            <BaseContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography color={'text.secondary'} variant={'h1'}>
                        {'Interviews'}
                    </Typography>
                    <img src={thumbsUp} style={{ marginLeft: '1.5rem' }} />
                </div>
                <div
                    style={{
                        minHeight: '100vh',
                        paddingTop: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem'
                    }}
                >
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label='interview status'
                        color='primary'
                        sx={{ mb: 8 }}
                    >
                        <Tab
                            label={
                                <Typography
                                    fontWeight={'600'}
                                    fontSize={'1rem'}
                                    style={{ textTransform: 'none' }}
                                >
                                    {'Upcoming'}
                                </Typography>
                            }
                            aria-label='home'
                            value='upcoming'
                        />
                        <Tab
                            label={
                                <Typography
                                    fontWeight={'600'}
                                    fontSize={'1rem'}
                                    style={{ textTransform: 'none' }}
                                >
                                    {'Completed'}
                                </Typography>
                            }
                            aria-label='interview'
                            value='completed'
                        />
                        {user.levylRole === 'candidate' && (
                            <Tab
                                label={
                                    <Typography
                                        fontWeight={'600'}
                                        fontSize={'1rem'}
                                        style={{ textTransform: 'none' }}
                                    >
                                        {'Follow-Up'}
                                    </Typography>
                                }
                                aria-label='interview'
                                value='followup'
                            />
                        )}
                    </Tabs>
                    {tab === 'upcoming' && (
                        <>
                            {interviews.soon.length > 0 && (
                                <div style={{ marginBottom: '2rem' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <EventIcon
                                            sx={{ mr: 1 }}
                                            color={'secondary'}
                                        />
                                        <Typography
                                            variant={'body2'}
                                            color={'secondary'}
                                        >
                                            {'Starting Soon'}
                                        </Typography>
                                    </div>
                                    <StyledGrid>
                                        {interviews.soon.map(
                                            (interview, interviewIndex) => (
                                                <InterviewCard
                                                    key={interviewIndex}
                                                    user={user}
                                                    interview={interview}
                                                    section={'soon'}
                                                    setModal={setModal}
                                                />
                                            )
                                        )}
                                    </StyledGrid>
                                </div>
                            )}

                            {Object.keys(upcomingByDate)
                                .sort()
                                .map((date, dateIndex) => (
                                    <div
                                        key={dateIndex}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            <EventIcon
                                                sx={{ mr: 1 }}
                                                color={'secondary'}
                                            />
                                            <Typography
                                                variant={'body2'}
                                                color={'secondary'}
                                            >
                                                {DateTime.fromFormat(
                                                    date,
                                                    'yyyy-MM-dd'
                                                ).toLocaleString(
                                                    DateTime.DATE_HUGE
                                                )}
                                            </Typography>
                                        </div>
                                        <StyledGrid>
                                            {upcomingByDate[date].map(
                                                (interview, interviewIndex) => (
                                                    <InterviewCard
                                                        key={interviewIndex}
                                                        user={user}
                                                        interview={interview}
                                                        section={'upcoming'}
                                                        setModal={setModal}
                                                    />
                                                )
                                            )}
                                        </StyledGrid>
                                    </div>
                                ))}
                        </>
                    )}
                    {tab === 'completed' && interviews.completed.length > 0 && (
                        <StyledGrid>
                            {interviews.completed.map(
                                (interview, interviewIndex) => (
                                    <InterviewCard
                                        key={interviewIndex}
                                        user={user}
                                        interview={interview}
                                        section={'completed'}
                                        setModal={setModal}
                                    />
                                )
                            )}
                        </StyledGrid>
                    )}
                    {tab === 'followup' && followup.length > 0 && (
                        <StyledGrid>
                            {followup.map((interview, interviewIndex) => (
                                <FollowupCard
                                    key={interviewIndex}
                                    user={user}
                                    interviews={interview.interviews}
                                />
                            ))}
                        </StyledGrid>
                    )}
                </div>
            </BaseContainer>
            <ModalSection
                modal={modal.type}
                closeModal={() =>
                    setModal({ type: '', interview: {} as Interview })
                }
                addContactOnClick={() => addContactOnClick(modal.interview)}
                removeContactOnClick={() =>
                    removeContactOnClick(modal.interview)
                }
                cancelInterviewOnClick={() =>
                    cancelInterviewOnClick(modal.interview)
                }
                interview={modal.interview}
            />
        </>
    );
}
