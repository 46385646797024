import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import ComputerIcon from '@mui/icons-material/Computer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
    getUserEducation,
    getWorkingPreference
} from '../../../services/convert/enum';
import { getJob } from '../../../services/jobs';
import { format } from '../../../utils/currency';
import { UserContext } from '../../context/UserContext';
import Button, { BackButton } from '../../global/components/Button/Button';
import { BaseCard, BaseContainer, ProfilePicture } from '../../global/Core';

import type { Job } from '../../../types/job';

const CardHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const CompanyButton = styled(ButtonBase)`
    display: flex;
    gap: 0.5rem;
`;

const JobHeader = styled('div')`
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
`;

const JobTitle = styled(Typography)`
    color: rgba(0, 0, 0, 0.6);
`;

const StatSection = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

const JobStat = styled('div')`
    display: flex;
    gap: 0.5rem;
`;

const BookSection = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
`;

const SummarySection = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

const StyledChipStack = styled(Stack)`
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
`;

const StyledChip = styled(Chip)`
    height: unset;
    padding: 0.5rem;
`;

export default function FullJobCard() {
    const [job, setJob] = useState<Job>();
    const { user } = useContext(UserContext);
    const theme = useTheme();
    const history = useHistory();
    const { jobID } = useParams<{ jobID: string }>();

    const salaryRange = `${format(
        job?.minSalary ?? 0,
        'en-CA',
        'CAD',
        0
    )} - ${format(job?.maxSalary ?? 0, 'en-CA', 'CAD', 0)}`;

    const jobStats = [
        {
            title: 'Role Category',
            value: job?.roleType,
            icon: EmojiPeopleIcon
        },
        {
            title: 'Company Size',
            value: `${job?.company.size} employees`,
            icon: BusinessIcon
        },
        {
            title: 'Education Type',
            value: getUserEducation(job?.educationType ?? 'none'),
            icon: SchoolIcon
        },
        {
            title: 'Working Environment',
            value: getWorkingPreference(job?.workPreference ?? 'no_preference'),
            icon: ComputerIcon
        },
        {
            title: 'Salary Range',
            value: !job?.hideSalary ? salaryRange : 'Hidden',
            icon: AttachMoneyIcon
        },
        {
            title: 'Experience',
            value: `${job?.minExperience} - ${job?.maxExperience} years`,
            icon: WorkIcon
        }
    ];

    useEffect(() => {
        if (jobID) {
            getJob(parseInt(jobID, 10)).then((response: Job) => {
                if (response) {
                    setJob(response);
                }
            });
        }
    }, [jobID]);

    return (
        <BaseContainer>
            <BaseCard variant='outlined'>
                <CardHeader>
                    <BackButton
                        onClick={() => {
                            history.goBack();
                        }}
                        startIcon={<KeyboardBackspaceIcon />}
                        color='primary'
                    />
                    <CompanyButton
                        component={Link}
                        to={`/secure/${user.userID}/company/${job?.companyID}`}
                    >
                        <ProfilePicture src={job?.company.logoURL} size={2} />
                        <Typography variant={'body2'} color='primary'>
                            {` ${job?.company.name}`}
                        </Typography>
                    </CompanyButton>
                </CardHeader>
                <JobHeader>
                    <JobTitle variant={'h6'} fontWeight={700}>
                        {job?.title}
                    </JobTitle>
                    <Typography>{job?.company.location}</Typography>
                    <Typography>
                        {`Posted: ${Math.round(
                            DateTime.now()
                                .diff(job?.createdAt ?? DateTime.now())
                                .as('days')
                        )}
                         days ago`}
                    </Typography>
                </JobHeader>
                <StatSection>
                    {jobStats.map((stat, index) => (
                        <JobStat key={index}>
                            <stat.icon
                                style={{ color: theme.palette.text.secondary }}
                            />
                            <Typography variant={'body2'} component={'span'}>
                                {`${stat.title}: `}
                                <Typography
                                    variant={'body2'}
                                    color={'text.secondary'}
                                    fontWeight={400}
                                    component={'span'}
                                >
                                    {stat.value}
                                </Typography>
                            </Typography>
                        </JobStat>
                    ))}
                </StatSection>
                <Divider variant='middle' flexItem />
                <BookSection>
                    <Typography variant={'body2'} sx={{ mb: 2 }}>
                        <Typography
                            variant={'body2'}
                            color='primary'
                            component='span'
                            fontWeight={700}
                            sx={{ mr: 0.5 }}
                        >
                            {
                                job?.interviews.filter(
                                    (interview) =>
                                        interview.status === 'available'
                                ).length
                            }
                        </Typography>
                        {'spots left'}
                    </Typography>
                    <Button
                        to={`/secure/${user.userID}/schedule/${job?.id}`}
                        variant='contained'
                    >
                        {'Book a slot'}
                    </Button>
                </BookSection>
                <Divider variant='middle' flexItem />
                <SummarySection>
                    <Typography style={{ marginTop: '1.5rem' }} variant={'h6'}>
                        {'Summary'}
                    </Typography>
                    <Typography
                        variant={'body2'}
                        dangerouslySetInnerHTML={{
                            __html: job?.shortDescription ?? ''
                        }}
                    ></Typography>
                    <Typography variant={'h6'}>{'Description'}</Typography>
                    <Typography
                        variant={'body2'}
                        dangerouslySetInnerHTML={{
                            __html: job?.description ?? ''
                        }}
                    ></Typography>
                    <Typography variant={'h6'}>{'Benefits'}</Typography>
                    <StyledChipStack>
                        {job?.benefits.map((keyword) => (
                            <StyledChip
                                label={keyword}
                                key={keyword}
                                variant={'outlined'}
                            />
                        ))}
                    </StyledChipStack>
                    <Typography variant={'h6'}>{'Keywords'}</Typography>
                    <StyledChipStack>
                        {job?.keywords.map((keyword) => (
                            <StyledChip
                                label={keyword}
                                key={keyword}
                                variant={'outlined'}
                            />
                        ))}
                    </StyledChipStack>
                </SummarySection>
            </BaseCard>
        </BaseContainer>
    );
}
