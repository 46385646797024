import React from 'react';

import Modal from '../../global/components/Modal/Modal';
import ProfileModal from '../Network/ProfileModal';

import type { Interview } from '../../../types/interview';
import type { NetworkConnection } from '../../../types/user';

const AddContactModal = (props: {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}) => (
    <Modal
        heading={'Add to Network?'}
        content={
            "By adding this contact to your network, you'll be able to view their profile and contact them after the interview."
        }
        open={props.open}
        onClose={props.onClose}
        primaryCTAContent={'Add to Network'}
        primaryCTAAction={props.onClick}
    />
);

const RemoveContactModal = (props: {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}) => (
    <Modal
        heading={'Are you sure you want to remove this contact?'}
        content={
            "If you remove this contact you won't be able to view their profile or contact them after the interview."
        }
        open={props.open}
        onClose={props.onClose}
        primaryCTAContent={'Remove Contact'}
        primaryCTAAction={props.onClick}
    />
);

const CancelInterviewModal = (props: {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}) => (
    <Modal
        heading={'Are you sure you want to cancel the interview?'}
        content={
            "If you cancel the interview you won't be able to continue it and save the candidate's personal information."
        }
        open={props.open}
        onClose={props.onClose}
        primaryCTAContent={'Cancel Interview'}
        primaryCTAAction={props.onClick}
    />
);

export const ModalSection = ({
    modal,
    closeModal,
    addContactOnClick,
    removeContactOnClick,
    cancelInterviewOnClick,
    interview
}: {
    modal:
        | 'addContact'
        | 'removeContact'
        | 'cancelInterview'
        | 'viewProfile'
        | '';
    closeModal: () => void;
    addContactOnClick: () => Promise<void>;
    removeContactOnClick: () => Promise<void>;
    cancelInterviewOnClick: () => Promise<void>;
    interview: Interview;
}) => (
    <>
        {modal === 'addContact' && (
            <AddContactModal
                open={modal === 'addContact'}
                onClose={closeModal}
                onClick={addContactOnClick}
            />
        )}
        {modal === 'removeContact' && (
            <RemoveContactModal
                open={modal === 'removeContact'}
                onClose={closeModal}
                onClick={removeContactOnClick}
            />
        )}
        {modal === 'cancelInterview' && (
            <CancelInterviewModal
                open={modal === 'cancelInterview'}
                onClose={closeModal}
                onClick={cancelInterviewOnClick}
            />
        )}
        {modal === 'viewProfile' && (
            <ProfileModal
                open={modal === 'viewProfile'}
                handleClose={closeModal}
                interviewIDs={[interview.id]}
                connection={
                    {
                        participantID: interview.candidateID,
                        userID: interview.participant?.userID,
                        firstName: interview.participant?.name.firstName,
                        lastName: interview.participant?.name.lastName,
                        currentJobTitle:
                            interview.participant?.name.currentJobTitle,
                        profilePicturePath: interview.participant?.name.imageURL
                    } as NetworkConnection
                }
            />
        )}
    </>
);

export default ModalSection;
