import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import Modal from '../../components/Modal/Modal';

import content from './content';
import {
    LeftSection,
    OverlayText,
    SingleStep,
    StepsContainer,
    StyledCard,
    StyledCloseButtonContainer,
    StyledContent,
    StyledImage,
    StyledImageContainer
} from './styles';

type ScreenType = 'candidate' | 'business' | 'job';

export default function ColumnCard(props: {
    closeScreen?: boolean;
    onClose?: () => Promise<void>;
    children: any;
    step?: 1 | 2 | 3;
    image?: {
        src: string;
        alt: string;
        overlayText?: {
            title?: string;
            body?: string;
        };
    };
    screenType?: ScreenType;
    wide?: boolean;
    hideImage?: boolean;
    id?: string;
    isComponent?: boolean;
}) {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <StyledCard isComponent={props.isComponent} id={props.id}>
                {props.closeScreen && (
                    <StyledCloseButtonContainer>
                        <IconButton
                            id='closeColumnCard'
                            aria-label='close'
                            sx={{ m: 1 }}
                            onClick={() => setModalOpen(true)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </StyledCloseButtonContainer>
                )}
                <StyledContent>
                    <LeftSection wide={props.wide}>
                        {props.step && (
                            <StepsContainer>
                                <SingleStep>
                                    <img
                                        src={
                                            props.step === 1
                                                ? content.steps.common.one.icon
                                                      .purple
                                                : content.steps.common.one.icon
                                                      .gray
                                        }
                                        alt='1'
                                        style={{ width: '2rem' }}
                                    />
                                    <Typography
                                        sx={{ fontSize: '0.75rem', mt: 1 }}
                                    >
                                        {
                                            content.steps[
                                                props.screenType ?? 'candidate'
                                            ].one.text
                                        }
                                    </Typography>
                                </SingleStep>
                                <SingleStep>
                                    <img
                                        src={
                                            props.step === 2
                                                ? content.steps.common.two.icon
                                                      .purple
                                                : content.steps.common.two.icon
                                                      .gray
                                        }
                                        alt='2'
                                        style={{ width: '2rem' }}
                                    />
                                    <Typography
                                        sx={{ fontSize: '0.75rem', mt: 1 }}
                                    >
                                        {
                                            content.steps[
                                                props.screenType ?? 'candidate'
                                            ].two.text
                                        }
                                    </Typography>
                                </SingleStep>
                                <SingleStep>
                                    <img
                                        src={
                                            props.step === 3
                                                ? content.steps.common.three
                                                      .icon.purple
                                                : content.steps.common.three
                                                      .icon.gray
                                        }
                                        alt='3'
                                        style={{ width: '2rem' }}
                                    />
                                    <Typography
                                        sx={{ fontSize: '0.75rem', mt: 1 }}
                                    >
                                        {
                                            content.steps[
                                                props.screenType ?? 'candidate'
                                            ].three.text
                                        }
                                    </Typography>
                                </SingleStep>
                            </StepsContainer>
                        )}
                        {props.children}
                    </LeftSection>
                    <StyledImageContainer>
                        <StyledImage
                            src={
                                props.image
                                    ? props.image.src
                                    : content.image.src
                            }
                            alt={
                                props.image
                                    ? props.image.alt
                                    : content.image.alt
                            }
                            hide={props.hideImage}
                        />
                        {props.image?.overlayText && (
                            <OverlayText>
                                <Typography
                                    sx={{ fontSize: '2rem' }}
                                    color='primary'
                                >
                                    {props.image.overlayText.title}
                                </Typography>
                                {props.image.overlayText.body && (
                                    <Typography>
                                        {props.image.overlayText.body}
                                    </Typography>
                                )}
                            </OverlayText>
                        )}
                    </StyledImageContainer>
                </StyledContent>
            </StyledCard>
            <Modal
                heading={content.modal.heading}
                content={content.modal.content}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                primaryCTAContent='Leave'
                primaryCTAAction={() => props.onClose && props.onClose()}
            />
        </>
    );
}
