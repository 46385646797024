import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { BaseCard, ProfilePicture } from '../../Core';

const StyledCard = styled(BaseCard)`
    display: flex;
    position: relative;
    margin: 0.5rem;
    width: 22rem;
    padding: 0;
    justify-content: center;
`;

const ButtonContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
`;

const StyledButton = styled(Button)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    color: ${theme.palette.primary.main};,
    gap: 0.25rem;
`
);

const StyledButtonText = styled(Typography)`
    display: flex;
    text-transform: none;
    text-align: center;
`;

const DrawerIconContainer = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const ContentContainer = styled('div')`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
`;

const TextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Name = styled(Typography)`
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
`;

const JobTitle = styled(Typography)`
    margin-bottom: 0.25rem;
    text-align: center;
`;

export default function ContactCard(props: {
    name: string;
    jobTitle: string;
    imageURL: string;
    drawerActions: {
        text: string;
        onClick?: () => void;
    }[];
    actions: {
        text: string;
        onClick?: () => void;
        to?: string;
        icon: React.ReactNode;
    }[];
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StyledCard variant='outlined'>
                    <DrawerIconContainer
                        aria-label='open menu'
                        onClick={handleClick}
                    >
                        <LinearScaleIcon />
                    </DrawerIconContainer>
                    <Menu
                        id='demo-customized-menu'
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button'
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock
                    >
                        {props.drawerActions.map((action, index) => (
                            <MenuItem key={index} onClick={action.onClick}>
                                <Typography>{action.text}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                    <div
                        style={{
                            display: 'flex',
                            paddingLeft: '3rem',
                            paddingRight: '3rem',
                            paddingTop: '2.5rem',
                            paddingBottom: '2.5rem'
                        }}
                    >
                        <ContentContainer>
                            <TextContainer>
                                <div>
                                    <ProfilePicture
                                        src={props.imageURL}
                                        size={6}
                                        sx={{ mr: 1 }}
                                    />
                                </div>
                                <Name variant={'body2'}>
                                    <strong>{props.name}</strong>
                                </Name>
                                <JobTitle
                                    variant={'body2'}
                                >{`${props.jobTitle}`}</JobTitle>
                            </TextContainer>
                            <ButtonContainer>
                                {props.actions.map((action, index) =>
                                    action.onClick ? (
                                        <StyledButton
                                            key={index}
                                            onClick={action.onClick}
                                        >
                                            {action.icon}
                                            <StyledButtonText
                                                variant={'body2'}
                                                component='span'
                                            >
                                                {action.text}
                                            </StyledButtonText>
                                        </StyledButton>
                                    ) : (
                                        <StyledButton
                                            key={index}
                                            component={Link}
                                            to={action.to}
                                        >
                                            {action.icon}
                                            <StyledButtonText
                                                variant={'body2'}
                                                component='span'
                                            >
                                                {action.text}
                                            </StyledButtonText>
                                        </StyledButton>
                                    )
                                )}
                            </ButtonContainer>
                        </ContentContainer>
                    </div>
                </StyledCard>
            </div>
        </>
    );
}
