import {
    convertLocalLuxonToUTCDateTime,
    convertUTCDateTimeToLocalLuxon
} from '../../utils/convertDate';

import { convertCompanyResponseToCompany } from './company';
import { convertSimpleJobResponseToSimpleJob } from './job';
import { convertUserResponseToUser } from './user';

import type {
    CreateInterviewDetails,
    Interview,
    InterviewResponse,
    SimpleInterview,
    SimpleInterviewAndTitle,
    SimpleInterviewResponse
} from '../../types/interview';

export const convertSimpleInterviewResponseToSimpleInterview = (
    response: SimpleInterviewResponse
): SimpleInterview => {
    const interview: SimpleInterview = {
        id: response.id,
        jobID: response.job_id,
        interviewerID: response.interviewer_id,
        startTime: convertUTCDateTimeToLocalLuxon(response.start_time),
        duration: response.duration,
        createdAt: convertUTCDateTimeToLocalLuxon(response.created_at),
        sessionID: response.session_id,
        candidateID: response.candidate_id,
        booked: response.booked,
        bookedAt: convertUTCDateTimeToLocalLuxon(response.booked_at),
        candidateMeetingToken: response.candidate_meeting_token,
        messageChatURL: response.message_chat_url,
        candidatePresent: response.candidate_present,
        interviewerPresent: response.interviewer_present,
        completedDuration: response.completed_duration,
        status: response.status,
        feedback: response.feedback.map((feedback) => ({
            question: {
                id: feedback.question.id,
                userID: feedback.question.user_id,
                question: feedback.question.question,
                systemGeneratedID: feedback.question.system_generated_id,
                numberResponse: feedback.question.number_response
            },
            answer: feedback.answer
                ? {
                      id: feedback.answer.id,
                      questionID: feedback.answer.question_id,
                      interviewID: feedback.answer.interview_id,
                      answer: feedback.answer.answer,
                      answeredByCandidate: feedback.answer.answered_by_candidate
                  }
                : feedback.answer
        }))
    };
    return interview;
};

export const convertSimpleInterviewAndTitleResponseToSimpleInterviewAndTitle = (
    response: SimpleInterviewResponse & { job_title: string }
): SimpleInterviewAndTitle => ({
    ...convertSimpleInterviewResponseToSimpleInterview(response),
    jobTitle: response.job_title
});

export const convertInterviewResponseToInterview = (
    response: InterviewResponse
): Interview => {
    const interview: Interview = {
        ...convertSimpleInterviewResponseToSimpleInterview(response),
        participant: response.participant
            ? convertUserResponseToUser(response.participant)
            : null,
        company: convertCompanyResponseToCompany(response.company),
        job: convertSimpleJobResponseToSimpleJob(response.job)
    };
    return interview;
};

export const convertCreateInterviewDetailsToCreateInterviewDetailsRequest = (
    interview: CreateInterviewDetails,
    jobID: number
) => {
    const interviewDetails = interview.details.map((detail) => ({
        duration: detail.duration.toString(),
        start_time: convertLocalLuxonToUTCDateTime(detail.startTime)
    }));
    return {
        job_id: jobID,
        interviewer_id: interview.interviewerID,
        split: interview.split,
        split_time: interview.splitTime,
        details: interviewDetails
    };
};
