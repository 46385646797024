import MaterialModal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';

import {
    createMockSoonInterviews,
    createMockUpcomingInterviews
} from '../../../../../services/admin';
import Button from '../../../components/Button/Button';

import type { User } from '../../../../../types/user';

const content = { modalClose: 'Cancel' };

const StyledModal = styled(MaterialModal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    max-width: 30rem;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
`;

const StyledModalAction = styled('div')`
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
`;

const ModalButton = styled(Button)`
    flex: 1;
`;

export function ModalContent(props: {
    heading: string;
    onClose: () => void;
    user: User;
}) {
    const { user } = props;
    const [participantID, setParticipantID] = React.useState('');
    const [availableLoading, setAvailableLoading] = React.useState(false);
    const [soonLoading, setSoonLoading] = React.useState(false);

    const mockSoon = useCallback(() => {
        setSoonLoading(true);
        createMockSoonInterviews(
            user.userID,
            user.levylRole === 'business',
            participantID || null
        )
            .then(() => {
                setSoonLoading(false);
            })
            .catch(() => {
                setSoonLoading(false);
            });
    }, [participantID, user]);

    const mockAvailable = useCallback(() => {
        setAvailableLoading(true);
        createMockUpcomingInterviews(user.userID, user.levylRole === 'business')
            .then(() => {
                setAvailableLoading(false);
            })
            .catch(() => {
                setAvailableLoading(false);
            });
    }, [user]);

    return (
        <StyledModalContent>
            <Typography id='modal-title' sx={{ fontWeight: 'bold' }}>
                {props.heading}
            </Typography>
            <div
                style={{
                    marginTop: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                }}
            >
                <Typography>
                    {
                        'Select add available interview slots to add interview slots that can be booked in the future, this will also populate the candidate dashboard'
                    }
                </Typography>
                <ModalButton
                    id='modalPrimaryCTAAction'
                    variant='contained'
                    onClick={mockAvailable}
                    loading={availableLoading}
                >
                    {'Add Available Interview Slots'}
                </ModalButton>
                <Typography>
                    {
                        'Select add interview if you want to add an upcoming interview to start soon. You can optionally add a participant ID if you want that interview to be with a specific person'
                    }
                </Typography>
                <TextField
                    label='Participant ID'
                    variant='outlined'
                    value={participantID}
                    onChange={(e) => setParticipantID(e.target.value)}
                />
            </div>
            <StyledModalAction>
                <ModalButton variant='outlined' onClick={props.onClose}>
                    {content.modalClose}
                </ModalButton>
                <ModalButton
                    id='modalPrimaryCTAAction'
                    variant='contained'
                    onClick={mockSoon}
                    loading={soonLoading}
                >
                    {'Add Interview'}
                </ModalButton>
            </StyledModalAction>
        </StyledModalContent>
    );
}

export default function TestPanel(props: {
    open: boolean;
    onClose: () => void;
    user: User;
}) {
    return (
        <StyledModal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
            disableScrollLock
        >
            <ModalContent
                heading={'Test Panel'}
                onClose={props.onClose}
                user={props.user}
            />
        </StyledModal>
    );
}
