import Daily, {
    DailyCall,
    DailyEvent,
    DailyParticipant
} from '@daily-co/daily-js';
import { useEffect, useState } from 'react';

const meetingEvents = [
    'joined-meeting',
    'left-meeting',
    'error'
] as DailyEvent[];

export const participantEvents = [
    'joined-meeting',
    'participant-joined',
    'participant-updated',
    'participant-left',
    'track-started',
    'track-stopped'
] as DailyEvent[];

const handleMeetingState = (call: DailyCall) => {
    if (!call) return;
    switch (call.meetingState()) {
        case 'joined-meeting':
            break;
        case 'left-meeting':
            call.destroy().then(() => {});
            break;
        case 'error':
            call.destroy().then(() => {});
            break;
        default:
            break;
    }
};

const updateActiveParticipants = (participants: {
    [x: string]: DailyParticipant;
}) => Object.keys(participants).map((x) => participants[x].user_name);

export default function useDailyQuery(
    roomID: string,
    meetingToken: string,
    joinCall: boolean
) {
    const [call, setCall] = useState<DailyCall | null>(null);
    const [activeParticipants, setActiveParticipants] = useState<string[]>([]);

    useEffect(() => {
        setCall(Daily.createCallObject());
    }, []);

    useEffect(() => {
        if (call) {
            meetingEvents.forEach((event) =>
                call.on(event, () => handleMeetingState(call))
            );
            participantEvents.forEach((event) => {
                call.on(event, () => {
                    setActiveParticipants(
                        updateActiveParticipants(call.participants())
                    );
                });
            });
            // TODO:
            // eslint-disable-next-line no-console
            console.log('roomID', roomID, meetingToken);
            if (roomID && joinCall) {
                call.join({
                    url: `https://levyl.daily.co/${roomID}`,
                    token: meetingToken || ''
                });
            }
        }

        return () => {
            if (call) {
                meetingEvents.forEach((event) =>
                    call.off(event, () => handleMeetingState(call))
                );
                participantEvents.forEach((event) => {
                    call.off(event, () => {
                        setActiveParticipants(
                            updateActiveParticipants(call.participants())
                        );
                    });
                });
            }
        };
    }, [call, roomID, meetingToken, joinCall]);

    return { call, activeParticipants };
}
