import { yupResolver } from '@hookform/resolvers/yup';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuid4 } from 'uuid';
import * as yup from 'yup';

import {
    checkWaitlist,
    createWaitlistBusiness,
    createWaitlistCandidate
} from '../../services/user';
import Button from '../global/components/Button/Button';
import TextInput from '../global/components/TextField/TextField';

import WaitlistModal from './WaitlistModal';

import type { UserType } from '../../types/enum';
import type { Control, FieldValues } from 'react-hook-form';

const StyledBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem 8rem',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0',

    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
    }
}));

const StyledContent = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: `${(5 / 12) * 100}%`,
    margin: 0,
    marginRight: '6rem',

    [theme.breakpoints.down('md')]: {
        minWidth: theme.spacing(32),
        flexBasis: '100%',
        marginBottom: theme.spacing(6),
        marginRight: '0'
    }
}));

const StyledCard = styled(Card)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    padding: '2rem',
    boxShadow:
        '0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)'
}));

const SubmitButton = styled(Button)``;

const StyledTextField = styled(TextInput)(({ theme }) => ({
    width: theme.spacing(36.5),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    '& label.MuiInputLabel-root': {
        color: theme.palette.primary.main,
        fontSize: theme.typography.body2.fontSize
    },
    '& .MuiInput-root': {
        borderBottomColor: theme.palette.primary.main,
        '&:after,&:before,&:hover:before': {
            borderBottomColor: theme.palette.primary.main
        }
    }
}));

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: `${(4 / 12) * 100}%`,
    [theme.breakpoints.down('md')]: {
        maxWidth: `${(7 / 12) * 100}%`,
        minWidth: theme.spacing(32)
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: `${(10 / 12) * 100}%`,
        minWidth: theme.spacing(32)
    }
}));

export type WaitlistType =
    | 'joinedWaitlist'
    | 'onWaitlist'
    | 'alreadyAccepted'
    | 'doesNotExist';

export interface WaitlistProps {
    image?: { file: { url: string } };
    heading: string;
    content: string;
    fieldHelperText: string;
    ctaText: string;
}

const formSchema = yup
    .object()
    .shape({
        email: yup.string().email().required('Required')
    })
    .required();

export default function WaitlistSection(props: WaitlistProps) {
    const [open, setOpen] = useState('');
    const [role, setRole] = useState<UserType>('business');
    const [loading, setLoading] = useState(false);

    const uid = `waitlist-${uuid4()}`;
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            email: ''
        }
    });

    useEffect(() => {
        const isCompaniesInURL = window.location.pathname.includes('companies');
        if (isCompaniesInURL) {
            setRole('business');
        } else {
            setRole('candidate');
        }
    }, []);

    useEffect(() => {
        if (loading && open !== '') {
            setLoading(false);
        }
    }, [loading, open]);

    const onSubmit = (data: { email: string }) => {
        setLoading(true);
        checkWaitlist(data.email, uid).then((response) => {
            if (response.startsWith('waitlist')) {
                setOpen('onWaitlist');
            } else if (response === 'accepted') {
                setOpen('alreadyAccepted');
            } else if (role === 'business') {
                createWaitlistBusiness(data.email, uid).then(() => {
                    setOpen('joinedWaitlist');
                });
            } else {
                createWaitlistCandidate(data.email, uid).then(() => {
                    setOpen('joinedWaitlist');
                });
            }
        });
    };

    return (
        <>
            <StyledBackground id='waitlist'>
                <StyledContainer disableGutters>
                    <StyledContent disableGutters>
                        <StyledCard>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                id={`waitlist-${role}`}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '600',
                                        lineHeight: '1.5'
                                    }}
                                    variant={'body2'}
                                    color='#676767'
                                    component='h6'
                                >
                                    {`${props.heading}*`}
                                </Typography>
                                <StyledTextField
                                    id='email'
                                    label={props.fieldHelperText}
                                    color='primary'
                                    size='small'
                                    error={!!errors.email}
                                    errorText={errors.email?.message}
                                    control={
                                        control as unknown as Control<FieldValues>
                                    }
                                />
                                <div>
                                    <SubmitButton
                                        variant='contained'
                                        submit
                                        id={`waitlist-submit-${role}`}
                                        loading={loading}
                                    >
                                        {props.ctaText}
                                    </SubmitButton>
                                </div>
                            </form>
                        </StyledCard>
                    </StyledContent>
                    {props.image && <StyledImage src={props.image.file.url} />}
                    <Typography
                        sx={{
                            mt: 5,
                            maxWidth: '60rem',
                            fontSize: '0.875rem'
                        }}
                        variant={'body2'}
                        color='#676767'
                        component='p'
                    >
                        {"*By joining our waitlist, you agree to Levyl's "}
                        <Link
                            href='/public/terms'
                            color='primary'
                            underline={'none'}
                        >
                            {'Terms of Use'}
                        </Link>
                        {' and '}
                        <Link
                            href='/public/privacy-policy'
                            color='primary'
                            underline={'none'}
                        >
                            {'Privacy Policy'}
                        </Link>
                        {
                            '. By providing your email, you consent to receive marketing communications from Levyl, with the option to unsubscribe at any time. For more details, please visit our Privacy Policy or contact us at support@levyl.app'
                        }
                    </Typography>
                </StyledContainer>
            </StyledBackground>
            {open !== '' && (
                <WaitlistModal
                    open={open !== ''}
                    onClose={() => setOpen('')}
                    waitlistType={open as WaitlistType}
                />
            )}
        </>
    );
}
