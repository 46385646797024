import { DailyCall, DailyEvent, DailyParticipant } from '@daily-co/daily-js';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';

import useTimer from '../hooks/useTimer';

import ChatScreenDisplay from './ChatScreenDisplay';

type ChatScreenProps = {
    call: DailyCall | null;
    startTime?: DateTime;
    endSingleInterview: () => void;
    preview?: boolean;
    callerID: string;
    callerName: string;
    callerProfilePicture: string;
    participantEvents: DailyEvent[];
    toggleSidebar: () => void;
    participantPresent?: boolean;
};

export default function ChatScreen(props: ChatScreenProps) {
    const { participantEvents, call } = props;

    const [tracks, setTracks] = useState<{
        localAudio: MediaStreamTrack | undefined;
        localVideo: MediaStreamTrack | undefined;
        callerAudio: MediaStreamTrack | undefined;
        callerVideo: MediaStreamTrack | undefined;
    }>({
        localAudio: undefined,
        localVideo: undefined,
        callerAudio: undefined,
        callerVideo: undefined
    });

    const timer = useTimer(props.startTime);

    const updateLocal = (stream: 'audio' | 'video', value: boolean) => {
        if (!call) return;
        if (stream === 'audio') {
            call.updateParticipant('local', { setAudio: value });
        } else if (stream === 'video') {
            call.updateParticipant('local', { setVideo: value });
        }
    };

    const getTracksByID = (
        user: string,
        participants: { [x: string]: DailyParticipant }
    ) => {
        // const callerID = Object.keys(participants).filter(
        //     (x) => participants[x].user_id === user
        // )[0];
        const callerID = Object.keys(participants).filter(
            (x) => x !== 'local'
        )[0];
        return {
            audio: participants[callerID]?.tracks.audio.track,
            video: participants[callerID]?.tracks.video.track
        };
    };

    const updateTracks = useCallback(() => {
        if (!call) return;
        setTracks((prevTracks) => {
            const newTracks = {
                ...prevTracks
            };
            if (Object.keys(call.participants()).includes('local')) {
                newTracks.localVideo = call.participants().local.videoTrack;
                newTracks.localAudio = call.participants().local.audioTrack;
            }
            const callerTracks = getTracksByID(
                props.callerID,
                call.participants()
            );
            if (props.callerID && props.participantPresent) {
                newTracks.callerVideo = callerTracks.video;
                newTracks.callerAudio = callerTracks.audio;
            }
            return newTracks;
        });
    }, [call, props.callerID, props.participantPresent]);

    useEffect(() => {
        updateTracks();
    }, [props.callerID, updateTracks]);

    useEffect(() => {
        if (call) {
            participantEvents.forEach((event) => call.on(event, updateTracks));
        }

        return () => {
            if (call) {
                participantEvents.forEach((event) =>
                    call.off(event, updateTracks)
                );
            }
        };
    }, [call, updateTracks, participantEvents]);

    return (
        <ChatScreenDisplay
            localAudio={tracks.localAudio}
            localVideo={tracks.localVideo}
            callerAudio={tracks.callerAudio}
            callerVideo={tracks.callerVideo}
            updateLocal={updateLocal}
            endSingleInterview={props.endSingleInterview}
            profilePicture={props.callerProfilePicture}
            name={props.callerName}
            timer={timer}
            preview={props.preview}
            toggleSidebar={props.toggleSidebar}
        />
    );
}
