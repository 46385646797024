import {
    JobOffer,
    JobOfferResponse,
    JobResponse,
    SimpleJob,
    SimpleJobResponse
} from '../types/job';
import { convertUTCDateTimeToLocalLuxon } from '../utils/convertDate';

import {
    convertJobResponseToJob,
    convertSimpleJobResponseToSimpleJob,
    convertSimpleJobToSimpleJobResponse
} from './convert/job';
import { del, get, post, put } from './http';

export const getJob = (jobID: number) =>
    get(`/api/job/${jobID}`).then((response: JobResponse) =>
        convertJobResponseToJob(response)
    );

export const createJob = (jobDict: SimpleJob) => {
    const jobResponse = convertSimpleJobToSimpleJobResponse(jobDict);
    return post('/api/job', jobResponse).then((response: SimpleJobResponse) =>
        convertSimpleJobResponseToSimpleJob(response)
    );
};

export const updateJob = (jobDict: SimpleJob) => {
    const jobResponse = convertSimpleJobToSimpleJobResponse(jobDict);
    return put('/api/job', jobResponse).then((response: SimpleJobResponse) =>
        convertSimpleJobResponseToSimpleJob(response)
    );
};

export const getUserJobs = (userID: number) =>
    get(`/api/job/user/${userID}`).then((response: JobResponse[]) =>
        response.map(convertJobResponseToJob)
    );

export const getCompanyJobs = (companyID: number) =>
    get(`/api/job/company/${companyID}`).then((response: JobResponse[]) =>
        response.map(convertJobResponseToJob)
    );

export const getJobsBySearch = (search: string) =>
    get(`/api/job/search/?search=${search}`);

export const addSavedJob = (userID: number, jobID: number) =>
    post(`/api/job/saved/${userID}/${jobID}`, {});

export const removeSavedJob = (userID: number, jobID: number) =>
    del(`/api/job/saved/${userID}/${jobID}`);

export const getSavedJobs = (userID: number) =>
    get(`/api/job/saved/${userID}`).then((response: JobResponse[]) =>
        response.map(convertJobResponseToJob)
    );

export const createJobOffer = (
    jobID: number,
    candidateID: number,
    managerID: number
) =>
    post('/api/job/offer', {
        job_id: jobID,
        candidate_id: candidateID,
        manager_id: managerID
    });

export const acceptJobOffer = (jobOfferID: number) =>
    put('/api/job/offer/accept', { job_offer_id: jobOfferID });

export const rejectJobOffer = (jobOfferID: number) =>
    put('/api/job/offer/reject', { job_offer_id: jobOfferID });

export const recindJobOffer = (jobOfferID: number) =>
    put('/api/job/offer/recind', { job_offer_id: jobOfferID });

export const getJobOffers = (userID: number) =>
    get(`/api/job/offer/${userID}`).then((response: JobOfferResponse[]) =>
        response.map(
            (jobOffer) =>
                ({
                    id: jobOffer.id,
                    jobID: jobOffer.job_id,
                    candidateID: jobOffer.candidate_id,
                    managerID: jobOffer.manager_id,
                    status: jobOffer.status,
                    createdAt: convertUTCDateTimeToLocalLuxon(
                        jobOffer.created_at
                    )
                } as JobOffer)
        )
    );
