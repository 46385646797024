import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

import type { InterviewStatus } from '../../../../types/enum';

export default function useSessionInterviewIDs(
    firestore: firebase.firestore.Firestore | null,
    interviewID: number
) {
    const [sessionID, setSessionID] = useState<string>('');
    const [intervivewIDs, setInterviewIDs] = useState<
        {
            id: number;
            candidatePresent: boolean;
            interviewerPresent: boolean;
            status: InterviewStatus;
        }[]
    >([]);

    useEffect(() => {
        const unsubscribe = firestore
            ?.collection('interview')
            .where('interview_id', '==', interviewID)
            .onSnapshot((snapshot) => {
                if (!snapshot.empty) {
                    setSessionID(snapshot.docs[0].data()?.session_id);
                }
            });
        return () => unsubscribe?.();
    }, [firestore, interviewID]);

    useEffect(() => {
        const unsubscribe = firestore
            ?.collection('interview')
            .where('session_id', '==', sessionID)
            .onSnapshot((snapshot) => {
                setInterviewIDs(
                    snapshot.docs.map((doc) => ({
                        id: doc.data()?.interview_id,
                        candidatePresent: doc.data()?.candidate_present,
                        interviewerPresent: doc.data()?.interviewer_present,
                        status: doc.data()?.status
                    }))
                );
            });
        return () => unsubscribe?.();
    }, [firestore, sessionID]);

    return intervivewIDs;
}
