import { Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { User } from '../../../types/user';
import SmallCard from '../../global/layouts/SmallCard/SmallCard';

import type { Interview } from '../../../types/interview';

export const InterviewCardContent = ({
    image,
    statusText,
    primaryAction,
    secondaryAction,
    jobTitle,
    companyName,
    name,
    drawerActions
}: {
    image: string;
    statusText: string;
    primaryAction?: { text: string; onClick?(): void; to?: string };
    secondaryAction?: { text: string; onClick?(): void; to?: string };
    jobTitle: string;
    companyName: string;
    name?: string;
    drawerActions?: { text: string; onClick: () => void }[];
}) => (
    <SmallCard
        imageSrc={image}
        statusText={statusText}
        primaryButton={primaryAction}
        secondaryButton={secondaryAction}
        drawerActions={drawerActions}
    >
        <Typography
            style={{ marginTop: '0.5rem', textAlign: 'center' }}
            fontWeight={600}
            variant='body1'
            color={'text.secondary'}
        >
            {companyName}
        </Typography>
        <Typography
            style={{ marginTop: '0.25rem', textAlign: 'center' }}
            fontWeight={600}
            variant='body1'
        >
            {jobTitle}
        </Typography>
        {name && (
            <Typography
                variant={'body2'}
                color={'text.secondary'}
                component='span'
                fontWeight={600}
                sx={{ mt: 1 }}
            >
                {name}
            </Typography>
        )}
    </SmallCard>
);

const InterviewCard = ({
    user,
    interview,
    section,
    setModal
}: {
    user: User;
    interview: Interview;
    section: 'soon' | 'upcoming' | 'completed';
    setModal: (modalObject: {
        interview: Interview;
        type:
            | 'addContact'
            | 'removeContact'
            | 'cancelInterview'
            | 'viewProfile';
    }) => void;
}) => {
    const primaryAction = useMemo(() => {
        if (section === 'soon') {
            return {
                text: 'Start Interview',
                to: `/secure/${user.userID}/interview/${interview.id}/lobby`
            };
        }
        if (section === 'completed' && user.levylRole === 'business') {
            return {
                text: 'Add to Network',
                onClick: () => setModal({ interview, type: 'addContact' })
            };
        }
        return undefined;
    }, [section, user.userID, interview, setModal, user.levylRole]);

    const secondaryAction = useMemo(() => {
        if (section === 'completed') {
            return {
                text: 'Remove Contact',
                onClick: () => setModal({ interview, type: 'removeContact' })
            };
        }
        return {
            text: 'Cancel',
            onClick: () => setModal({ interview, type: 'cancelInterview' })
        };
    }, [section, setModal, interview]);

    const statusText = useMemo(() => {
        if (section === 'completed') {
            return 'Waiting for response';
        }
        const startTime = interview.startTime.toFormat('h:mm a');
        const endTime = interview.startTime
            .plus({ minutes: interview.duration })
            .toFormat('h:mm a');
        return `${startTime} - ${endTime}`;
    }, [section, interview.startTime, interview.duration]);

    return (
        <InterviewCardContent
            image={interview.company.logoURL}
            statusText={statusText}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            jobTitle={interview.job.title}
            companyName={interview.company.name}
            name={
                section === 'completed' || user.levylRole === 'candidate'
                    ? `${interview.participant?.name.firstName} ${interview.participant?.name.lastName}`
                    : undefined
            }
            drawerActions={
                user.levylRole === 'business' && section === 'completed'
                    ? [
                          {
                              text: 'View Profile',
                              onClick: () =>
                                  setModal({ interview, type: 'viewProfile' })
                          }
                      ]
                    : undefined
            }
        />
    );
};

export default InterviewCard;
