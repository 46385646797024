import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';

const StyledPreviewContainer = styled(Container)(({ theme, isSelected }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem 1rem 1rem 1.5rem',
    margin: '0',
    cursor: 'pointer',
    transition: '.3s',
    width: '100%',
    backgroundColor: isSelected ? 'rgba(118, 40, 201, 0.06)' : '',
    borderTop: '1px solid #EFEFEF',
    '&:hover': {
        backgroundColor: 'rgba(118, 40, 201, 0.06)',
        transition: '.3s'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '1rem 1.5rem 1rem 2.5rem'
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}));

const StyledPreviewContentContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '0',
    padding: '0',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}));

interface CustomChannelPreviewProps {
    channelURL: string;
    channelUnreadCount: number;
    currentChannelURL: string;
    setCurrentChannelURL: (channelURL: string) => void;
    displayName: string;
    profileImageURL: string;
}

const CustomChannelPreview = ({
    channelURL,
    channelUnreadCount,
    currentChannelURL,
    setCurrentChannelURL,
    displayName,
    profileImageURL
}: CustomChannelPreviewProps) => {
    const forwardedRef = useRef<HTMLDivElement>(null);

    return (
        <StyledPreviewContainer
            isSelected={channelURL === currentChannelURL}
            onClick={() => {
                setCurrentChannelURL(channelURL);
            }}
            ref={forwardedRef}
        >
            <Badge
                variant='dot'
                color='primary'
                invisible={channelUnreadCount === 0}
            >
                <Avatar
                    alt={displayName}
                    src={profileImageURL}
                    sx={{
                        width: 48,
                        height: 48,
                        mr: 1,
                        bgcolor: '#C4C4C4'
                    }}
                />
            </Badge>
            <StyledPreviewContentContainer>
                <Typography
                    sx={{
                        fontWeight: 400,
                        lineHeight: '1',
                        ml: 1
                    }}
                    variant={'body2'}
                    color='text.secondary'
                    component='h3'
                    mb={1}
                >
                    {displayName}
                </Typography>
            </StyledPreviewContentContainer>
        </StyledPreviewContainer>
    );
};

export default CustomChannelPreview;
