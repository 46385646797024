import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { useTimer as useTimerHook } from 'react-timer-hook';

export const isLessThanAnHourFromNow = (startTime: DateTime) => {
    const diff = startTime.diff(DateTime.now()).as('minutes');
    return diff < 60 && diff > 0;
};

export default function useTimer(startTime?: DateTime, onExpire?: () => void) {
    const { seconds, minutes, restart } = useTimerHook({
        expiryTimestamp: DateTime.fromObject({ year: 3000 }).toJSDate(),
        onExpire
    });
    const restartTimer = useRef(restart);

    useEffect(() => {
        restartTimer.current = restart;
    }, [restart]);

    useEffect(() => {
        if (startTime) {
            const newTime = startTime || DateTime.fromMillis(0);
            restartTimer.current(newTime.toJSDate());
        }
    }, [startTime]);

    return {
        seconds,
        minutes,
        restart
    };
}
