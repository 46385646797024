/* eslint-disable max-lines */
import { styled, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCompanyJobs, getJobOffers } from '../../../services/jobs';
import { getNetworkConnections, getUserByID } from '../../../services/user';
import thumbsUp from '../../../static/icons/thumbs-up.svg';
import { UserContext } from '../../context/UserContext';
import { BaseContainer } from '../../global/Core';

import ContactCard from './ContactCard';

import type { SimpleInterview } from '../../../types/interview';
import type { Job, JobOffer } from '../../../types/job';
import type { NetworkConnection } from '../../../types/user';

type CandidateByStatus = NetworkConnection & { offers: JobOffer[] };

type GroupCandidateByStatus = {
    offers: CandidateByStatus[];
    contacts: CandidateByStatus[];
    pending: CandidateByStatus[];
    declined: CandidateByStatus[];
};

const ContentContainer = styled('div')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    padding-top: 4rem;
    gap: 1rem;
`;

export default function CandidateNetwork() {
    const [contacts, setContacts] = useState<NetworkConnection[]>([]);
    const [offers, setOffers] = useState<JobOffer[]>([]);
    const [tab, setTab] = useState<'offers' | 'contacts' | 'search'>(
        'contacts'
    );
    const [searchByJobID, setSearchByJobID] = useState(0);
    const [openJobs, setOpenJobs] = useState<Job[]>([]);
    const [jobCandidates, setJobCandidates] = useState<GroupCandidateByStatus>({
        offers: [],
        contacts: [],
        pending: [],
        declined: []
    });

    const { jobID } = useParams<{ jobID: string }>();

    const { user, companies } = useContext(UserContext);

    const openOfferContactIDs = useMemo(
        () =>
            offers
                .filter((offer) => offer.status === 'pending')
                .map((offer) =>
                    user.levylRole === 'candidate'
                        ? offer.managerID
                        : offer.candidateID
                ),
        [offers, user.levylRole]
    );

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: 'offers' | 'contacts' | 'search'
    ) => {
        setTab(newValue);
    };

    const handleSearchByJobID = (event: SelectChangeEvent) =>
        setSearchByJobID(parseInt(event.target.value, 10));

    useEffect(() => {
        if (user.id) {
            getNetworkConnections(user.id).then(setContacts);
            getJobOffers(user.id).then((offerlist: JobOffer[]) => {
                setOffers(offerlist);
            });
        }
    }, [user.id]);

    useEffect(() => {
        if (companies.length > 0) {
            const jobList = companies.map((company) =>
                getCompanyJobs(company.id)
            );
            Promise.all(jobList).then((jobs) => {
                setOpenJobs(
                    jobs
                        .flat()
                        .filter(
                            (job) =>
                                job.open &&
                                job.interviews.some(
                                    (interview: SimpleInterview) =>
                                        interview.status.startsWith('completed')
                                ) &&
                                !offers.some(
                                    (offer) =>
                                        offer.jobID === job.id &&
                                        offer.status === 'accepted'
                                )
                        )
                        .sort((a, b) => b.createdAt.diff(a.createdAt))
                );
            });
        }
    }, [companies, offers]);

    useEffect(() => {
        if (jobID) {
            setTab('search');
        }
        if (openJobs.length > 0) {
            if (jobID) {
                setSearchByJobID(parseInt(jobID, 10));
            } else {
                setSearchByJobID(openJobs[0].id);
            }
        }
    }, [openJobs, jobID]);

    useEffect(() => {
        const candidatesByStatus = {
            offers: [],
            contacts: [],
            pending: [],
            declined: []
        } as GroupCandidateByStatus;

        if (!searchByJobID || tab !== 'search') return;

        const currentJob = openJobs.find((job) => job.id === searchByJobID);
        currentJob?.interviews
            .filter((interview) => interview.status.startsWith('completed'))
            .forEach((interview) => {
                const { candidateID } = interview;
                const candidateOffers = offers
                    .filter(
                        (offer) =>
                            offer.candidateID === candidateID &&
                            offer.status === 'pending'
                    )
                    .sort((a, b) =>
                        b.createdAt.diff(a.createdAt).as('seconds')
                    );
                const networkConnection = contacts.find(
                    (connection) => connection.participantID === candidateID
                );
                let status = 'pending' as keyof GroupCandidateByStatus;
                if (candidateOffers.length > 0) {
                    status = 'offers';
                } else if (networkConnection) {
                    status = 'contacts';
                }
                let candidateIndex = candidatesByStatus[status].findIndex(
                    (candidate) => candidate.participantID === candidateID
                );
                if (candidateIndex === -1) {
                    candidateIndex = candidatesByStatus.declined.findIndex(
                        (candidate) => candidate.participantID === candidateID
                    );
                }
                if (candidateIndex > -1) {
                    candidatesByStatus[status][candidateIndex].offers.push(
                        ...candidateOffers
                    );
                    candidatesByStatus[status][candidateIndex].interviews.push({
                        id: interview.id,
                        jobID: interview.jobID,
                        companyID: currentJob.companyID
                    });
                } else {
                    if (interview.status !== 'completed') {
                        status = 'declined';
                    }
                    let newCandidate = {
                        id: interview.id,
                        createdAt: interview.createdAt,
                        messageChatURL: interview.messageChatURL,
                        participantID: candidateID,
                        userID: '',
                        firstName: '',
                        lastName: '',
                        currentJobTitle: '',
                        profilePicturePath: '',
                        interviews: [
                            {
                                id: interview.id,
                                jobID: interview.jobID,
                                companyID: currentJob.companyID
                            }
                        ],
                        offers: candidateOffers
                    };

                    if (networkConnection) {
                        newCandidate = {
                            ...networkConnection,
                            offers: candidateOffers
                        };
                    }
                    candidatesByStatus[status].push(newCandidate);
                }
            });
        setJobCandidates(candidatesByStatus);

        (
            ['pending', 'declined'] as (keyof typeof candidatesByStatus)[]
        ).forEach(async (status) => {
            const candidateUsers = candidatesByStatus[status].map((candidate) =>
                getUserByID(candidate.participantID)
            );
            await Promise.all(candidateUsers).then((users) => {
                candidatesByStatus[status] = candidatesByStatus[status].map(
                    (candidate, idx) => ({
                        ...candidate,
                        userID: users[idx].userID,
                        firstName: users[idx].name.firstName,
                        lastName: users[idx].name.lastName,
                        currentJobTitle: users[idx].name.currentJobTitle,
                        profilePicturePath: users[idx].name.imageURL
                    })
                );
                setJobCandidates((prev) => ({
                    ...prev,
                    [status]: candidatesByStatus[status]
                }));
            });
        });
    }, [offers, openJobs, searchByJobID, contacts]);

    return (
        <BaseContainer>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem'
                }}
            >
                <Typography color={'text.secondary'} variant={'h1'}>
                    {'Network'}
                </Typography>
                <img src={thumbsUp} style={{ marginLeft: '1.5rem' }} />
            </div>
            <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label='interview status'
                color='primary'
            >
                <Tab
                    label={
                        <Typography
                            fontWeight={'600'}
                            fontSize={'1rem'}
                            style={{ textTransform: 'none' }}
                        >
                            {'Contacts'}
                        </Typography>
                    }
                    value='contacts'
                />
                <Tab
                    label={
                        <Typography
                            fontWeight={'600'}
                            fontSize={'1rem'}
                            style={{ textTransform: 'none' }}
                        >
                            {'Job Offers'}
                        </Typography>
                    }
                    value='offers'
                />
                {user.levylRole === 'business' && (
                    <Tab
                        label={
                            <Typography
                                fontWeight={'600'}
                                fontSize={'1rem'}
                                style={{ textTransform: 'none' }}
                            >
                                {'Search by Role'}
                            </Typography>
                        }
                        value='search'
                    />
                )}
            </Tabs>
            {tab === 'offers' && (
                <ContentContainer container spacing={2}>
                    {contacts
                        .filter((connection) =>
                            openOfferContactIDs.includes(
                                connection.participantID
                            )
                        )
                        .map((contact, idx) => (
                            <ContactCard
                                key={idx}
                                contact={contact}
                                uid={user.id}
                                userID={user.userID}
                                role={user.levylRole}
                                offer={offers.find((offer) =>
                                    offer.status === 'pending' &&
                                    user.levylRole === 'candidate'
                                        ? offer.managerID ===
                                          contact.participantID
                                        : offer.candidateID ===
                                          contact.participantID
                                )}
                                status={'offers'}
                                interviewIDs={contact.interviews.map(
                                    (interview) => interview.id
                                )}
                            ></ContactCard>
                        ))}
                </ContentContainer>
            )}
            {tab === 'contacts' && (
                <ContentContainer container spacing={2}>
                    {contacts
                        .filter(
                            (connection) =>
                                !openOfferContactIDs.includes(
                                    connection.participantID
                                )
                        )
                        .map((contact, idx) => (
                            <ContactCard
                                key={idx}
                                contact={contact}
                                uid={user.id}
                                userID={user.userID}
                                role={user.levylRole}
                                status={'contacts'}
                                interviewIDs={contact.interviews.map(
                                    (interview) => interview.id
                                )}
                            ></ContactCard>
                        ))}
                </ContentContainer>
            )}
            {tab === 'search' && (
                <>
                    <InputLabel id='job-select-label' sx={{ mt: 3 }}>
                        {'Search by Role'}
                    </InputLabel>
                    <Select
                        labelId='job-select-label'
                        id='job-select'
                        value={searchByJobID.toString()}
                        onChange={handleSearchByJobID}
                        style={{ width: '100%' }}
                        color={'primary'}
                        sx={{ mt: 1 }}
                        MenuProps={{
                            disableScrollLock: true
                        }}
                    >
                        {openJobs.map((job) => (
                            <MenuItem key={job.id} value={job.id}>
                                {`${job.title} - ID #${job.id}`}
                            </MenuItem>
                        ))}
                    </Select>
                    {(
                        [
                            'offers',
                            'contacts',
                            'pending',
                            'declined'
                        ] as (keyof GroupCandidateByStatus)[]
                    ).map((status) => (
                        <>
                            <Typography
                                variant={'h2'}
                                color={'secondary'}
                                sx={{ mt: 3 }}
                            >
                                {`${status
                                    .charAt(0)
                                    .toUpperCase()}${status.slice(
                                    1,
                                    status.length
                                )}`}
                            </Typography>
                            <ContentContainer
                                container
                                spacing={2}
                                sx={{ pt: 1 }}
                            >
                                {jobCandidates[status].map((contact, idx) => (
                                    <ContactCard
                                        key={idx}
                                        contact={contact}
                                        interviewIDs={contact.interviews.map(
                                            (interview) => interview.id
                                        )}
                                        uid={user.id}
                                        userID={user.userID}
                                        role={user.levylRole}
                                        offer={contact.offers[0] ?? undefined}
                                        status={status}
                                    />
                                ))}
                            </ContentContainer>
                        </>
                    ))}
                </>
            )}
        </BaseContainer>
    );
}
