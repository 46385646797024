import { post } from './http';

export const createWaitlistCandidateMock = (apiKey: string) =>
    post(`/api/admin/mocks/waitlist-candidate?api_key=${apiKey}`, {});

export const createMockUpcomingInterviews = (
    userID: string,
    isManager: boolean = false
) =>
    post('/api/admin/mocks/interviews/upcoming', {
        user_id: userID,
        is_manager: isManager
    });

export const createMockSoonInterviews = (
    userID: string,
    isManager: boolean = false,
    participantID: string | null = null
) =>
    post('/api/admin/mocks/interviews/soon', {
        user_id: userID,
        is_manager: isManager,
        participant_id: participantID
    });
