import {
    convertNetworkConnectionResponseToNetworkConnection,
    convertNetworkMessageContactResponseToNetworkMessageContact,
    convertParsedResumetoUserPartial,
    convertUserResponseToUser,
    convertUserToUserResponse
} from './convert/user';
import { get, post, put } from './http';

import type { CandidateSearch } from '../types/job';
import type {
    NetworkConnection,
    NetworkConnectionResponse,
    NetworkMessageContact,
    NetworkMessageContactResponse,
    ResumeResponse,
    User,
    UserResponse
} from '../types/user';

export const createWaitlistCandidate = (
    email: string,
    userID: string
): Promise<User> =>
    post('/api/user/candidate/waitlist', { email, user_id: userID }).then(
        (response: UserResponse) => convertUserResponseToUser(response)
    );

export const createWaitlistBusiness = (
    email: string,
    userID: string
): Promise<User> =>
    post('/api/user/business/waitlist', { email, user_id: userID }).then(
        (response: UserResponse) => convertUserResponseToUser(response)
    );

export const checkWaitlist = (
    email: string,
    uid: string = ''
): Promise<
    'waitlist_candidate' | 'waitlist_business' | 'accepted' | 'not_found'
> => post('/api/user/check_waitlist', { email, uid });

export const createCandidate = (email: string, userID: string) =>
    post('/api/user/candidate', { email, user_id: userID }).then(
        (response: UserResponse) => convertUserResponseToUser(response)
    );

export const createBusiness = (email: string, userID: string) =>
    post('/api/user/business', { email, user_id: userID }).then(
        (response: UserResponse) => convertUserResponseToUser(response)
    );

export const updateUser = (userDict: User) => {
    const userResponse = convertUserToUserResponse(userDict);
    return put('/api/user', userResponse).then((response: UserResponse) =>
        convertUserResponseToUser(response)
    );
};

export const getUser = (userID: string) =>
    get(`/api/user/${userID}`).then((response: UserResponse) =>
        convertUserResponseToUser(response)
    );

export const getUserByID = (userID: number): Promise<User> =>
    get(`/api/user/id/${userID}`).then((response: UserResponse) =>
        convertUserResponseToUser(response)
    );

export const getEligibleUserCount = (searchDict: CandidateSearch) =>
    post('/api/user/eligible', searchDict).then(
        (response: { count: number }) => response?.count || 0
    );

export const setUserOnline = (userID: number) =>
    put('/api/user/online', { id: userID });

export const setUserOffline = (userID: number) =>
    put('/api/user/offline', { id: userID });

export const addNetworkConnection = (candidateID: number, managerID: number) =>
    put('/api/user/network/add', {
        candidate_id: candidateID,
        manager_id: managerID
    });

export const removeNetworkConnection = (
    candidateID: number,
    managerID: number
) =>
    put('/api/user/network/remove', {
        candidate_id: candidateID,
        manager_id: managerID
    });

export const getNetworkConnections = (
    userID: number
): Promise<NetworkConnection[]> =>
    get(`/api/user/network/${userID}`).then(
        (response: NetworkConnectionResponse[]) =>
            response.map(convertNetworkConnectionResponseToNetworkConnection)
    );

export const getNetworkMessageContacts = (
    userID: number
): Promise<NetworkMessageContact[]> =>
    get(`/api/user/network/message/${userID}`).then(
        (response: NetworkMessageContactResponse[]) =>
            response.map(
                convertNetworkMessageContactResponseToNetworkMessageContact
            )
    );

export const getUserWithInterviewFeedback = (
    userID: number,
    selectedUserID: number
) => get(`/api/user/feedback/${userID}/${selectedUserID}`);

export const parseResume = (userID: string) =>
    post('/api/user/parseresume', { user_id: userID });

export const getParsedResumeResult = (parseID: string) =>
    get(`/api/user/parseresume/${parseID}`).then(
        (response: {
            status: 'succeeded' | 'processing' | 'failed';
            data: ResumeResponse;
        }) =>
            response.status === 'succeeded'
                ? {
                      status: response.status,
                      data: convertParsedResumetoUserPartial(response.data)
                  }
                : response
    );
