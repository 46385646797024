import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import RichText from '../../../global/components/RichText';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { User } from '../../../../types/user';
import type { Control, FieldValues } from 'react-hook-form';

type StringExperience = {
    title: string;
    company: string;
    startDate: string;
    endDate: string;
    description: string;
};

const formSchema = yup.object().shape({
    experience: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            company: yup.string(),
            startDate: yup.string(),
            endDate: yup.string(),
            description: yup.string()
        })
    )
});

const convertLuxonToString = (data: User['experience']) =>
    data.map((experience) => ({
        ...experience,
        startDate: experience.startDate.toFormat('yyyy-MM-dd'),
        endDate: experience.endDate.toFormat('yyyy-MM-dd')
    }));

const convertStringToLuxon = (data: StringExperience[]) =>
    data.map((experience) => ({
        ...experience,
        startDate: DateTime.fromFormat(experience.startDate, 'yyyy-MM-dd'),
        endDate: DateTime.fromFormat(experience.endDate, 'yyyy-MM-dd')
    }));

export default function ExperienceSlide(props: {
    profile: User['experience'];
    updateProfile: (value: User['experience']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            experience: convertLuxonToString(props.profile)
        }
    });
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'experience'
    });

    const onSubmit = (data: { experience: StringExperience[] }) => {
        props.updateProfile(convertStringToLuxon(data.experience));
    };

    useEffect(() => {
        replace(convertLuxonToString(props.profile));
    }, [replace, props.profile]);

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'experienceSlide'}
            wide
            hideImage
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {'Tell us about where you have worked before.'}
                </Typography>
                {fields.map((field, index) => (
                    <div key={field.id} style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #C2C2C2',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                marginTop: '1rem',
                                flex: 1
                            }}
                        >
                            <TextInput
                                id={`experience.${index}.title`}
                                label={content.experience.title.label}
                                color='primary'
                                error={!!errors.experience?.[index]?.title}
                                errorText={
                                    errors.experience?.[index]?.title?.message
                                }
                                placeholder={
                                    content.experience.title.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                            <TextInput
                                id={`experience.${index}.company`}
                                label={content.experience.company.label}
                                color='primary'
                                error={!!errors.experience?.[index]?.company}
                                errorText={
                                    errors.experience?.[index]?.company?.message
                                }
                                placeholder={
                                    content.experience.company.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    gap: '1rem',
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.5rem'
                                }}
                            >
                                <Controller
                                    name={`experience.${index}.startDate`}
                                    control={control}
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterLuxon}
                                        >
                                            <DatePicker
                                                label='Start date'
                                                format={'YYYY-MM-DD'}
                                                value={DateTime.fromFormat(
                                                    value,
                                                    'yyyy-MM-dd'
                                                )}
                                                onChange={(newValue) =>
                                                    onChange(
                                                        newValue?.toFormat(
                                                            'yyyy-MM-dd'
                                                        )
                                                    )
                                                }
                                                sx={{ flex: 1 }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                <Controller
                                    name={`experience.${index}.endDate`}
                                    control={control}
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterLuxon}
                                        >
                                            <DatePicker
                                                label='End date'
                                                format={'YYYY-MM-DD'}
                                                value={DateTime.fromFormat(
                                                    value,
                                                    'yyyy-MM-dd'
                                                )}
                                                onChange={(newValue) =>
                                                    onChange(
                                                        newValue?.toFormat(
                                                            'yyyy-MM-dd'
                                                        )
                                                    )
                                                }
                                                sx={{ flex: 1 }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>
                            <RichText
                                id={`experience.${index}.description`}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                        </div>
                        {fields.length > 1 && (
                            <IconButton
                                id={`experience.${index}.remove`}
                                color='primary'
                                aria-label={content.experience.remove}
                                component='label'
                                sx={{ ml: 1 }}
                                onClick={() => remove(index)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        )}
                    </div>
                ))}
                <div style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
                    <TextButton
                        id={'addExperience'}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ fontWeight: 400, mt: 2 }}
                        onClick={() =>
                            append({
                                startDate: '',
                                endDate: '',
                                title: '',
                                company: '',
                                description: ''
                            })
                        }
                    >
                        {content.experience.add}
                    </TextButton>
                </div>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
