/* eslint-disable max-lines */
import { styled } from '@mui/material';
import MUIButton from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import {
    cancelInterview,
    getInterviewsForUser
} from '../../../services/interviews';
import { getJob } from '../../../services/jobs';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CreateInterviewDetails } from '../../../types/interview';
import { TextButton } from '../../global/components/Button/Button';
import Modal from '../../global/components/Modal/Modal';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

import type { SimpleInterview } from '../../../types/interview';
import type { Job, SimpleJob } from '../../../types/job';

const TimeSlotButton = styled(MUIButton)`
    padding-left: 3rem;
    padding-right: 3rem;
    @media (max-width: 600px) {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
`;

const getOpenUpcomingInterviews = (interviews: SimpleInterview[]) =>
    interviews.filter(
        (interview) =>
            ['available', 'scheduled', 'follow_up_requested'].includes(
                interview.status
            ) && interview.startTime > DateTime.now()
    );

const checkIfTimeIsAvailable = (
    time: { startTime: DateTime; duration: number },
    interviews: { startTime: DateTime; duration: number }[]
) => {
    const endTime = time.startTime.plus({
        minutes: time.duration
    });
    return interviews.every(
        (interview) =>
            !(
                (interview.startTime < time.startTime &&
                    endTime < interview.startTime) ||
                (interview.startTime.plus({
                    minutes: interview.duration
                }) > time.startTime &&
                    interview.startTime.plus({
                        minutes: interview.duration
                    }) < endTime)
            )
    );
};

export default function ScheduleSlide(props: {
    job: SimpleJob;
    submitInterview: (
        interviewDetails: CreateInterviewDetails['details']
    ) => void;
    candidateCount: number;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit: boolean;
}) {
    const [duration, setDuration] = useState<number>(15);

    const [timeList, setTimeList] = useState<
        { startTime: DateTime; duration: number }[]
    >([]);

    const [value, setValue] = useState<DateTime | null>(DateTime.now());

    const addValue = (newValue: { startTime: DateTime; duration: number }) => {
        setTimeList([...timeList, newValue]);
    };

    const removeTime = (index: number) => {
        setTimeList(timeList.filter((_, i) => i !== index));
    };

    const [jobInterviews, setJobInterviews] = useState<SimpleInterview[]>([]);
    const [interviewerInterviews, setInterviewerInterviews] = useState<
        SimpleInterview[]
    >([]);
    const [modal, setModal] = useState<'cancel' | 'not available' | ''>('');
    const [interviewID, setInterviewID] = useState<number | null>(null);

    useEffect(() => {
        if (props.isEdit && props.job.id !== 0) {
            getJob(props.job.id).then((job: Job) => {
                setJobInterviews(getOpenUpcomingInterviews(job.interviews));
            });
            getInterviewsForUser(props.job.createdByID).then(
                (interviews: SimpleInterview[]) => {
                    setInterviewerInterviews(
                        getOpenUpcomingInterviews(interviews)
                    );
                }
            );
        }
    }, [props.isEdit, props.job.id, props.job.createdByID]);

    return (
        <>
            <ColumnCard
                closeScreen
                step={3}
                screenType={'job'}
                image={{
                    src: candidateCountImg,
                    alt: 'total candidates'
                }}
                onClose={props.onClose}
            >
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {'Create Job Posting'}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    {'Book some slots when you are available for interviews.'}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1rem'
                    }}
                >
                    <ButtonGroup
                        variant='outlined'
                        aria-label='Basic button group'
                        style={{ display: 'flex' }}
                    >
                        <TimeSlotButton
                            onClick={() => setDuration(15)}
                            variant={duration === 15 ? 'contained' : 'outlined'}
                        >
                            {'15'}
                        </TimeSlotButton>
                        <TimeSlotButton
                            onClick={() => setDuration(30)}
                            variant={duration === 30 ? 'contained' : 'outlined'}
                        >
                            {'30'}
                        </TimeSlotButton>
                        <TimeSlotButton
                            onClick={() => setDuration(45)}
                            variant={duration === 45 ? 'contained' : 'outlined'}
                        >
                            {'45'}
                        </TimeSlotButton>
                        <TimeSlotButton
                            onClick={() => setDuration(60)}
                            variant={duration === 60 ? 'contained' : 'outlined'}
                        >
                            {'60'}
                        </TimeSlotButton>
                    </ButtonGroup>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginTop: '2rem'
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                            label='Select a date and time'
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            onClose={() => {
                                if (
                                    checkIfTimeIsAvailable(
                                        {
                                            startTime: value as DateTime,
                                            duration
                                        },
                                        [
                                            ...timeList,
                                            ...interviewerInterviews.map(
                                                (interview) => ({
                                                    duration,
                                                    startTime:
                                                        interview.startTime
                                                })
                                            )
                                        ]
                                    )
                                ) {
                                    addValue({
                                        startTime: value as DateTime,
                                        duration
                                    });
                                } else {
                                    setModal('not available');
                                }
                            }}
                            disablePast
                        />
                    </LocalizationProvider>
                </div>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 1, mt: 2 }}
                >
                    {'Upcoming Interviews'}
                </Typography>
                {props.isEdit && jobInterviews.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '1rem'
                        }}
                    >
                        <Stack direction='column' spacing={1}>
                            {jobInterviews.map((time, index) => (
                                <Chip
                                    label={`${time.startTime.toLocaleString(
                                        DateTime.DATETIME_SHORT
                                    )} - ${time.duration} min - ${time.status}`}
                                    variant={'outlined'}
                                    color={'primary'}
                                    key={index}
                                    onDelete={() => {
                                        setInterviewID(time.id);
                                        setModal('cancel');
                                    }}
                                />
                            ))}
                        </Stack>
                    </div>
                )}
                {props.isEdit && (
                    <Typography
                        variant='h2'
                        component='h1'
                        fontWeight='400'
                        sx={{ mb: 1, mt: 2 }}
                    >
                        {'Interviews to Create'}
                    </Typography>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '1rem'
                    }}
                >
                    <Stack direction='column' spacing={1}>
                        {timeList.map((time, index) => (
                            <Chip
                                label={`${time.startTime.toLocaleString(
                                    DateTime.DATETIME_SHORT
                                )} - ${time.duration} min - ${Math.floor(
                                    time.duration / 8
                                )} interviews`}
                                variant={'outlined'}
                                color={'primary'}
                                key={index}
                                onDelete={() => removeTime(index)}
                            />
                        ))}
                    </Stack>
                </div>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {content.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='contained'
                        sx={{ m: 2 }}
                        onClick={() => props.submitInterview(timeList)}
                    >
                        {props.isEdit ? 'Update' : content.common.post}
                    </StyledButton>
                </NextSteps>
            </ColumnCard>
            <Modal
                heading={'Are you sure you want to cancel this interview?'}
                content={'This action cannot be undone.'}
                open={modal === 'cancel'}
                onClose={() => setModal('')}
                primaryCTAContent={'Cancel Interview'}
                primaryCTAAction={() =>
                    cancelInterview(interviewID as number, false)
                }
            />
            <Modal
                heading={'Cannot create interview at this time'}
                content={
                    'This time slot is already taken by another interview you have booked.'
                }
                open={modal === 'not available'}
                onClose={() => setModal('')}
                primaryCTAContent={'Okay'}
                primaryCTAAction={() => setModal('')}
            />
        </>
    );
}
