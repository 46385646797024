/* eslint-disable max-lines */
import MUIButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import { createWaitlistCandidateMock } from '../../../services/admin';
import Button from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';

type MockFunction = 'mockWaitlistUser';

const mockFunctions = {
    mockWaitlistUser: 'Mock Waitlist User'
};

export default function AdminPage() {
    const [mockFunction, setMockFunction] =
        useState<MockFunction>('mockWaitlistUser');
    const [apiKey, setApiKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<any>(undefined);

    const submitRequest = () => {
        let requestPromise = Promise.resolve();
        setLoading(true);
        switch (mockFunction) {
            case 'mockWaitlistUser':
                requestPromise = createWaitlistCandidateMock(apiKey);
                break;
            default:
                break;
        }
        requestPromise
            .then((response) => {
                setResult(response);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    return (
        <MainContainer
            sideBarButtons={[]}
            firstName={''}
            photoURL={''}
            signOut={() => {}}
        >
            <ColumnCard>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: '1rem'
                    }}
                >
                    <Typography
                        variant='h2'
                        component='h1'
                        fontWeight='400'
                        sx={{ mb: 2 }}
                    >
                        {'Mock User Generation'}
                    </Typography>
                    <Stack
                        direction='row'
                        flexWrap={'wrap'}
                        sx={{ gap: '0.5rem' }}
                    >
                        {Object.entries(mockFunctions).map(([key, value]) => (
                            <MUIButton
                                key={key}
                                variant={
                                    key === mockFunction
                                        ? 'contained'
                                        : 'outlined'
                                }
                                sx={{
                                    textTransform: 'none',
                                    textWrap: 'nowrap'
                                }}
                                onClick={() =>
                                    setMockFunction(key as MockFunction)
                                }
                            >
                                {value}
                            </MUIButton>
                        ))}
                    </Stack>
                    <TextField
                        label='API Key'
                        variant='outlined'
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                    />
                    <Button
                        variant='contained'
                        onClick={submitRequest}
                        loading={loading}
                    >
                        {'Generate Mock Data'}
                    </Button>
                    {result?.signup_link && (
                        <div>
                            <p>{'Sign in details:'}</p>
                            <p>{`Email: ${result?.email}`}</p>
                            <p>{`Sign in link: ${result.signup_link}`}</p>
                        </div>
                    )}
                </div>
            </ColumnCard>
        </MainContainer>
    );
}
