import {
    ChannelListProvider,
    useChannelListContext
} from '@sendbird/uikit-react/ChannelList/context';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import '@sendbird/uikit-react/dist/index.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getNetworkMessageContacts } from '../../../../services/user';
import { UserContext } from '../../../context/UserContext';

import {
    StyledChannelList,
    StyledChannelListContainer,
    StyledChannelListWrapper
} from './ChannelList.styles';
import ChannelPreview from './ChannelPreview';
import ChatHeader from './ChatHeader';

import type { NetworkMessageContact } from '../../../../types/user';

const ChannelList = ({
    channelURL,
    setChannelURL
}: {
    channelURL: string;
    setChannelURL: (url: string) => void;
}) => {
    const [unreadChats, setUnreadChats] = useState(0);
    const [networkContacts, setNetworkContacts] = useState<
        NetworkMessageContact[]
    >([]);
    const { allChannels } = useChannelListContext();
    const { user } = useContext(UserContext);

    useEffect(() => {
        let newUnreadChats = 0;
        allChannels.forEach((channel) => {
            if (channel.unreadMessageCount > 0) {
                newUnreadChats += channel.unreadMessageCount;
            }
        });
        setUnreadChats(newUnreadChats);
    }, [allChannels]);

    useEffect(() => {
        getNetworkMessageContacts(user.id).then(setNetworkContacts);
    }, [user.id]);

    const displayList = useMemo(
        () =>
            networkContacts.map((contact) => {
                const channel = allChannels.find(
                    (channelInfo) => channelInfo.url === contact.messageChatURL
                );
                return {
                    displayName: `${contact.firstName} ${contact.lastName}`,
                    profileImageURL: contact.profilePicturePath,
                    channelURL: contact.messageChatURL,
                    unreadMessageCount: channel?.unreadMessageCount || 0
                };
            }),
        [allChannels, networkContacts]
    );

    return (
        <StyledChannelListWrapper>
            <ChatHeader unreadChats={unreadChats} />
            <StyledChannelListContainer>
                <StyledChannelList>
                    {displayList.map((channel) => (
                        <ChannelPreview
                            key={channel.channelURL}
                            channelURL={channel.channelURL}
                            channelUnreadCount={channel.unreadMessageCount}
                            currentChannelURL={channelURL}
                            setCurrentChannelURL={setChannelURL}
                            displayName={channel.displayName}
                            profileImageURL={channel.profileImageURL}
                        />
                    ))}
                </StyledChannelList>
            </StyledChannelListContainer>
        </StyledChannelListWrapper>
    );
};

const ChannelListWithProvider = (props: {
    channelURL: string;
    setChannelURL: (channelURL: string) => void;
}) => {
    const { uid, messageID } = useParams<{ uid: string; messageID: string }>();
    const { channelURL, setChannelURL } = props;
    const history = useHistory();

    useEffect(() => {
        if (messageID) {
            setChannelURL(messageID);
        }
    }, [messageID, setChannelURL]);

    const setChannel = (channel: string) => {
        setChannelURL(channel);
        history.push(`/secure/${uid}/messaging/${channel}`);
    };

    return (
        <SendbirdProvider
            appId={'E464F6AC-4712-468C-8CCF-4B1D4B130ECA'}
            userId={uid}
            nickname={'Athavan'}
        >
            <ChannelListProvider
                queries={{
                    channelListQuery: {
                        includeEmpty: true
                    }
                }}
            >
                <ChannelList
                    channelURL={channelURL}
                    setChannelURL={setChannel}
                />
            </ChannelListProvider>
        </SendbirdProvider>
    );
};

export default ChannelListWithProvider;
